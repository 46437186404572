import { BlendingModel, RealTimeRollingAvg } from "@gms/measurement-api";
import { IDataState } from "../app/app.models";
import { IHistoricalGasDetails, IHistoricalGasDetailsSuccess } from "./cbh.actions";
export interface CBHReceiptPointState {
    isLoading?: boolean,
    receiptPointData: IDataState<RealTimeRollingAvg>,
    error?: any,
}

export const initialGasReceiptPointState: CBHReceiptPointState = {
    isLoading: false,
    receiptPointData: null,
    error: null
}

export interface RollingAvgState {
    isLoading?: boolean,
    rollingAvgData: IDataState<RealTimeRollingAvg>,
    error?: any,
}

export const initialRollingAvgState: RollingAvgState = {
    isLoading: false,
    rollingAvgData: null,
    error: null
}

export interface StrattonRidgeDataState {
    isLoading?: boolean,
    strattonData: IDataState<BlendingModel>,
    error?: any,
}

export const initialStrattonRidgeDataState: StrattonRidgeDataState = {
    isLoading: false,
    strattonData: null,
    error: null
}
export interface IHistoricalGasQualityState {
    error: Error | null;
    loading: boolean;
    historicalGasDetails: IHistoricalGasDetails;
    historicalGasSuccessDetails: IHistoricalGasDetailsSuccess;
}

export const initialHistoricalGasQualityState: IHistoricalGasQualityState = {
    loading: true,
    error: null,
    historicalGasDetails: {
        fromDate: undefined,
        toDate: undefined,
        authUser:''

    },
    historicalGasSuccessDetails: {
        fileByteArray: ''
    }
}