import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { FetchHistoricalGasQuality, FetchHistoricalGasQualityError, FetchHistoricalGasQualitySuccess, FetchReceiptGasComposition, FetchReceiptGasCompositionError, FetchReceiptGasCompositionSuccess, FetchRollingAvgGasComposition, FetchRollingAvgGasCompositionError, FetchRollingAvgGasCompositionSuccess, FetchStrattonRidgeGasComposition, FetchStrattonRidgeGasCompositionError, FetchStrattonRidgeGasCompositionSuccess, ReceiptGasPointActions } from "./cbh.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { BlendingModel, CoastalBendHeaderService, RealTimeRollingAvg } from '@gms/measurement-api'

@Injectable()
export class CBHEffects {
    constructor(
        private _actions$: Actions, 
        private cbhService: CoastalBendHeaderService, 
    ) {}

    FetchReceiptGasCompositionData$: Observable<any> = createEffect(() =>
       this._actions$.pipe(
        ofType<FetchReceiptGasComposition>(ReceiptGasPointActions.FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA),
        switchMap(() => {
            return this.cbhService.getRealTimeData().pipe(
                map((result) => {
                    const data: RealTimeRollingAvg = result;
                    return new FetchReceiptGasCompositionSuccess({data})
                }),
                catchError(error => of(new FetchReceiptGasCompositionError({error: error})))
            )
        })
       ) 
    );

    FetchRollingAvgGasCompositionData$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
         ofType<FetchRollingAvgGasComposition>(ReceiptGasPointActions.FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA),
         switchMap(() => {
             return this.cbhService.getCbhRollingAverage().pipe(
                 map((result) => {
                     const data: RealTimeRollingAvg = result;
                     return new FetchRollingAvgGasCompositionSuccess({data})
                 }),
                 catchError(error => of(new FetchRollingAvgGasCompositionError({error: error})))
             )
         })
        ) 
     );

    FetchStrattonRidgeGasCompositionData$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
         ofType<FetchStrattonRidgeGasComposition>(ReceiptGasPointActions.FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA),
         switchMap(() => {
             return this.cbhService.getStrattonRidgeDetails().pipe(
                 map((result) => {
                     const data: BlendingModel = result;
                     return new FetchStrattonRidgeGasCompositionSuccess({data})
                 }),
                 catchError(error => of(new FetchStrattonRidgeGasCompositionError({error: error})))
             )
         })
        ) 
    );

    FetchHistoricalGasQuality$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
            ofType<FetchHistoricalGasQuality>(ReceiptGasPointActions.FETCH_HISTORICAL_GAS_QUALITY),
            map((action: FetchHistoricalGasQuality) => action.payload),
            switchMap(payload => {

                return this.cbhService
                    .getCbhHistoricalData(
                        payload.fromDate,payload.toDate, payload.authUser
                    ).pipe(
                        map(
                            (result: string) =>
                                new FetchHistoricalGasQualitySuccess({
                                    fileByteArray: result
                                })
                        ),
                        catchError(error => of(new FetchHistoricalGasQualityError(error)))
                    );
            })
        )
    );
    
}