import { Routes } from "@angular/router";

export const CBH_Receipt_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/coastal-bend-header/pages/receipt-point-gas-composition/receipt-point-gas-composition.module').then(
        m => m.ReceiptPointGasCompositionModule
      ),
    data: {
      type: 'receipt',
      aclResource: 'coastalbendheader/posting-gas-composition',
    },
  },
];

export const CBH_Stratton_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/coastal-bend-header/pages/receipt-point-gas-composition/receipt-point-gas-composition.module').then(
        m => m.ReceiptPointGasCompositionModule
      ),
    data: {
      type: 'stratton',
      aclResource: 'coastalbendheader/posting-gas-composition-forecast',
    },
  },
];

export const CBH_Historical_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/coastal-bend-header/pages/historical-gas-quality/historical-gas-quality.module').then(
        m => m.HistoricalGasQualityModule
      ),
    data: {
      aclResource: 'CoastalBendHeader/PostingGasCompositionHistoricalPage',
    },
  },
];