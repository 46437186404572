import { Action } from "@ngrx/store";
import { BlendingModel, RealTimeRollingAvg } from "@gms/measurement-api";

export enum ReceiptGasPointActions {
    FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA = '[CBH] Fetch Receipt Point Gas Composition Data',
    FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA_SUCCESS = '[CBH] Fetch Receipt Point Gas Composition Data Success',
    FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA_ERROR = '[CBH] Fetch Receipt Point Gas Composition Data Error',

    FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA = '[RGC] Fetch Rolling Avg Gas Composition Data',
    FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA_SUCCESS = '[RGC] Fetch Rolling Avg Gas Composition Data Success',
    FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA_ERROR = '[RGC] Fetch Rolling Avg Gas Composition Data Error',

    FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA = '[SRD] Fetch Stratton Ridge Gas Composition Data',
    FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA_SUCCESS = '[SRD] Fetch Stratton Ridge Gas Composition Data Success',
    FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA_ERROR = '[SRD] Fetch Stratton Ridge Gas Composition Data Error',

    FETCH_HISTORICAL_GAS_QUALITY = '[HISTORICAL_GAS_QUALITY] Fetch Historical Gas Quality',
    FETCH_HISTORICAL_GAS_QUALITY_SUCCESS = '[HISTORICAL_GAS_QUALITY] Fetch Historical Gas Quality Success',
    FETCH_HISTORICAL_GAS_QUALITY_ERROR = '[HISTORICAL_GAS_QUALITY] Fetch Historical Gas Quality Error',
}

export class FetchReceiptGasComposition implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA;
}

export class FetchReceiptGasCompositionSuccess implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA_SUCCESS;
    constructor(public payload: {data: RealTimeRollingAvg}) {}
}

export class FetchReceiptGasCompositionError implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA_ERROR;
    constructor(public payload: {error: Error}) {}
}

export class FetchRollingAvgGasComposition implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA;
}

export class FetchRollingAvgGasCompositionSuccess implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA_SUCCESS;
    constructor(public payload: {data: RealTimeRollingAvg}) {}
}

export class FetchRollingAvgGasCompositionError implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA_ERROR;
    constructor(public payload: {error: Error}) {}
}

export class FetchStrattonRidgeGasComposition implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA;
}

export class FetchStrattonRidgeGasCompositionSuccess implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA_SUCCESS;
    constructor(public payload: {data: BlendingModel}) {}
}

export class FetchStrattonRidgeGasCompositionError implements Action {
    public readonly type = ReceiptGasPointActions.FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA_ERROR;
    constructor(public payload: {error: Error}) {}
}

export class FetchHistoricalGasQuality implements Action {

    public readonly type = ReceiptGasPointActions.FETCH_HISTORICAL_GAS_QUALITY;

    constructor(public payload: IHistoricalGasDetails) { }
}

export class FetchHistoricalGasQualitySuccess implements Action {

    public readonly type = ReceiptGasPointActions.FETCH_HISTORICAL_GAS_QUALITY_SUCCESS;

    constructor(public payload: IHistoricalGasDetailsSuccess) { }
}

export class FetchHistoricalGasQualityError implements Action {

    public readonly type = ReceiptGasPointActions.FETCH_HISTORICAL_GAS_QUALITY_ERROR;

    constructor(public payload: { error: Error }) { }
}

export interface IHistoricalGasDetails {
    fromDate:Date,
    toDate:Date
    authUser:string
}
    
export interface IHistoricalGasDetailsSuccess {
    fileByteArray: string;
}

export type CBHActions = 
| FetchReceiptGasComposition
| FetchReceiptGasCompositionSuccess
| FetchReceiptGasCompositionError
| FetchRollingAvgGasComposition
| FetchRollingAvgGasCompositionSuccess
| FetchRollingAvgGasCompositionError
| FetchStrattonRidgeGasComposition
| FetchStrattonRidgeGasCompositionSuccess
| FetchStrattonRidgeGasCompositionError
| FetchHistoricalGasQuality 
| FetchHistoricalGasQualitySuccess 
| FetchHistoricalGasQualityError;