import { CBHReceiptPointState, IHistoricalGasQualityState, initialGasReceiptPointState, initialHistoricalGasQualityState, initialRollingAvgState, initialStrattonRidgeDataState, RollingAvgState, StrattonRidgeDataState } from "./cbh.state";
import { CBHActions, ReceiptGasPointActions } from "./cbh.actions";
import { createErrorState, createLoadingState, createSuccessState } from "../app/app.models";

export function cbhReducers(
    state: CBHReceiptPointState = initialGasReceiptPointState,
    action: CBHActions
): CBHReceiptPointState {
    switch(action.type) {
        case ReceiptGasPointActions.FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA:
            return {
                ...state,
                receiptPointData: createLoadingState(state.receiptPointData)
            }
        case ReceiptGasPointActions.FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA_SUCCESS:
            return {
                ...state,
                receiptPointData: createSuccessState(action.payload.data)
            }
        case ReceiptGasPointActions.FETCH_RECEIPT_POINT_GAS_COMPOSITION_DATA_ERROR:
            return {
                ...state,
                error: createErrorState(action.payload.error)
            }
        default:
            return state          
    }
}

export function cbhRollingAvgReducers(
    state: RollingAvgState = initialRollingAvgState,
    action: CBHActions
): RollingAvgState {
    switch(action.type) {
        case ReceiptGasPointActions.FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA:
            return {
                ...state,
                rollingAvgData: createLoadingState(state.rollingAvgData)
            }
        case ReceiptGasPointActions.FETCH_ROLLING_AVG_GAS_COMPOSITION_DATA_SUCCESS:
            return {
                ...state,
                rollingAvgData: createSuccessState(action.payload.data)
            }
        default:
            return state        
    }
}

export function cbhStrattonReducers(
    state: StrattonRidgeDataState = initialStrattonRidgeDataState,
    action: CBHActions
) : StrattonRidgeDataState {
    switch(action.type) {
        case ReceiptGasPointActions.FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA: 
            return {
                ...state,
                strattonData: createLoadingState(state.strattonData)
            }
        case ReceiptGasPointActions.FETCH_STRATTON_RIDGE_GAS_COMPOSITION_DATA_SUCCESS:
            return {
                ...state,
                strattonData: createSuccessState(action.payload.data)
            }
        default:
            return state 
    }
}

export function historicalGasQualityReducers(
    state: IHistoricalGasQualityState = initialHistoricalGasQualityState,
    action: CBHActions
): IHistoricalGasQualityState {
    switch (action.type) {
        case ReceiptGasPointActions.FETCH_HISTORICAL_GAS_QUALITY:
            return {
                ...state,
                loading: true,
                error: null,
                historicalGasDetails: action.payload
            }

        case ReceiptGasPointActions.FETCH_HISTORICAL_GAS_QUALITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                historicalGasSuccessDetails: action.payload
            }

        default:
            return state;
    }
}